.label {
	font: $fontStack;
	font-size: 12px;
	display: block;
	text-transform: uppercase;
	font-weight: 700;

	&.funding {
		font-size: 13px; } }

.desc-row {
	margin: 0 0 17px !important;
	font-size: 14px;
	padding-bottom: 5px;
	border-bottom: 1px dashed #ddd;

	.fa {
		color: $green;
		margin-right: 5px; } }

.project .render {
	font-size: 18px; }

.project .risk, .project .first-payment {
	text-align: right;
	width: 53%; }

.project .deadline, .project .location {
	vertical-align: top;
	width: 45%; }

.project .deadline {
	font-size: 15px; }

.deadline {
	.label {
		margin-bottom: 5px; } }

.risk, .deadline, .location, .first-payment {
	display: inline-block;
	margin: 0;
	padding: 0; }

.interest, .duration {
	display: block; }

.closed {
	text-align: center; }

.seemore {
	display: block;
	padding: 10px;
	margin: 0 auto;
	text-align: center;
	text-decoration: none;
	color: #fff;
	background-color: $green;
	border-radius: 5px;
	font-weight: 500;
	position: relative;
	border: 2px solid $green;
	transition: all .3s ease;

	&:hover {
		background-color: transparent;
		color: $darkGray;
		transition: all .3s ease; } }

.render-value,
.duration-value,
.tag-value {
	font-size: 14px;
	padding: 7px;
	vertical-align: middle;
	display: inline-block;
	line-height: 8px;
	// width: 20px
	float: right;
	height: 24px;
	border: 1px solid $green;
	border-radius: 5px;
	text-align: center;
	box-sizing: border-box;
	color: #fff;
	background-color: $green;
	font-weight: 700; }

