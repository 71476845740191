.project {
	padding-top: 1px;
	padding-bottom: 40px;
	-webkit-font-smoothing: subpixel-antialiased;

	.label {
		font-size: 14px; }

	.desc-row {
		margin-bottom: 14px !important;
		font-size: 18px;
		font-family: proxima-nova, "Proxima Nova", sans-serif; }


	&::after {
		content: "";
		display: block;
		clear: both; }

	p.desc-row {
		font-size: 14px; }


	.project__header {
		width: 100%;
		background-color: #f5f5f5;
		background: #f5f5f5;

		&::after {
			content: "";
			display: block;
			clear: both; } }


	.achieved, .goal, .over {
		font-size: 20px;
		font-weight: 700;
		display: inline-block;
 }		// width: 30%

	.achieved {
		color: $green; }

	.over {
		margin: 0 2px;
		text-align: center; }

	.goal {
		text-align: right; }

	.progress-container, .progress-fill {
		height: 25px;
		border-radius: 6px; }

	.progress-container {
		margin-top: 5px; }

	.progress-fill {
		min-width: 25px;
		text-align: center;
		font-size: 13px;
		font-weight: 700;
		line-height: 25px;
		color: #fff;
		line-height: 25px; }

	.project__title {
		margin-top: 0;
		text-align: center;
		font-size: 50px;
		font-weight: 800;
		text-transform: uppercase;
		letter-spacing: 1px;
		line-height: 1.3;
		padding-top: 75px;
		margin-bottom: 0px;
		transition: font-size .3s ease; }

	.project__subtitle {
		margin-top: 0;
		text-align: center;
		font-size: 28px;
		font-weight: 800;
		text-transform: uppercase;
		letter-spacing: 1px;
		line-height: 1.3;
		margin-bottom: 15px;
		transition: font-size .3s ease; }

	.project__holder {
		text-align: center;
		font-size: 14px;
		text-transform: uppercase;

		.name {
			color: $green;
			text-transform: none; } }

	.image__container {
		height: 472px;
		border-radius: 6px;
		overflow: hidden;
		margin-top: 50px; }


	.project__img {
		width: 100%;
		height: 100%;
		position: relative;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%); }


	.label {
		&.funding {
			font-size: 18px;
			margin-bottom: 8px; } }

	.project__status {
		padding: 0 15px 15px;
		float: right;
		margin-top: 50px;
		.tooltip {
			color: #dc3545; }
		.tooltip:not(.static) {
			color: #dc3545;
			@media (max-width: 540px) {
				position: absolute;
				right: 10px;
				top: 5px;
				.tooltiptext {
					margin-right: 0px;
					margin-left: 0px;
					left: auto;
					right: -10px;
					&:after {
						left: auto;
						right: 15px; } } } }
		.status__container {
			width: 100%;
			padding: 0 20px 20px;
			float: right;
			border-radius: 6px;

			// box-shadow: 0 0 5px rgba( 0, 0, 0, .2 )
			.desc-row:first-of-type {
				position: relaitve; }

			@media (max-width: 540px) {
				.deadline, .risk {
					width: 100%;
					display: block;
					text-align: left; }
				.risk {
					position: relative;
					margin-top: 10px;
					.tooltiptext {
						margin-right: 0px;
						margin-left: 0px;
						left: auto;
						right: -10px;
						&:after {
							left: auto;
							right: 15px; } } } } } }

	.render-value,
	.duration-value,
	.tag-value {
		font-weight: 700; }


	.seemore {
		margin-bottom: 25px; }

	.subscribeForm {
		fieldset {
			padding-left: 0px;
			input {
				margin-bottom: 5px; }
			.checkbox {
				margin-top: 10px;
				label {
					margin-bottom: 0; } } }
		.seemore {
			width: 100%;
			text-transform: none; } }


	.side-btn {
		width: 80%;
		margin: auto;
		padding: 10px;
		text-decoration: none !important;
		color: #fff !important;
		background-color: $green;
		border-radius: 5px;
		font-weight: 500;
		position: relative;
		border: 2px solid $green;
		transition: all .3s ease;

		&:hover {
			background-color: transparent;
			color: $darkGray !important;
			transition: all .3s ease; } }


	.links {
		margin-top: 15px;

		a {
			display: block;
			margin-bottom: 10px;
			font-size: 14px;
			color: $green;
			text-decoration: none;

			&:hover {
				text-decoration: underline; } } }

	.project__desc {
		padding-top: 10px;

		h2 {
			margin: 0;
			margin-top: 20px; }

		p {
			font-family: "Forum";
			font-size: 16px;
			margin-top: 5px;
			margin-bottom: 35px; }

		img {
			max-width: 100%;
			margin: 15px 0; } }

	.video-wrap {
		position: relative;
		padding-bottom: 56.25%;
		// padding-top: 25px
		height: 0;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%; } }

	.map-container {
		position: relative;
		.overlay {
			background: transparent;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0; } } }

@media (max-width: 1080px) {
	.project {
		.status__container {
			padding: 0 !important; }

		.image__container {
			height: 500px; } } }

@media (max-width: 880px) {
	.project {
		.project__title {
			font-size: 35px;
			transition: font-size .3s ease; }
		.project__subtitle {
			font-size: 24px; }
		.image__container {
			height: 380px; } } }

@media (max-width: 700px) {
	.project {
		.project__title {
			font-size: 25px;
			transition: font-size .3s ease; }
		.project__subtitle {
			font-size: 18px; }
		.image__container {
			height: 300px; } } }

@media (max-width: 500px) {
	.project {
		.image__container {
			height: 220px; } } }

@media (max-width: 360px) {
	.project {
		.image__container {
			height: 180px; } } }
