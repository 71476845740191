.newsletter {
	height: 100%;
	transition: height .3s ease;
	min-height: 100%;
	background-image: url( "../img/newsletter-bg.jpg" );
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	color: #fff;
	text-align: center;
	box-sizing: border-box;
	position: relative;

	.page-container {
		padding-top: 50px;
		box-sizing: border-box; }

	img.bg {
		position: fixed;
		z-index: -2;
		top: 0;
		min-height: 100%;
		min-width: 1680px;
		width: 100%;
		height: auto;
		top: 0;
		left: 0; }

	h2 {
		font-size: 36px;
		font-weight: 300;
		text-transform: uppercase;
		margin-bottom: 15px; }

	p {
		font-size: 20px;
		margin-bottom: 25px; }

	.label {
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 300;
		margin-bottom: 10px; }

	input[type="text"], input[type="email"] {
		background-color: #fff;
		display: inline-block;
		border: none;
		color: $green;
		width: 300px;
		font-size: 14px;
		padding: 10px 15px;
		margin-bottom: 100px;
		border-radius: 6px;

		&:focus {
			outline: none;
			box-shadow: 0 0 15px rgba( 0, 0, 0, .3 ); } }

	button[type="submit"] {
		display: inline-block;
		background-color: $green;
		border: none;
		padding: 10px 15px;
		border-radius: 6px;
		font-size: 14px;
		margin-left: 5px;
		text-transform: none;

		&:hover {
			color: #fff;
			box-shadow: 0 0 15px rgba( 93, 166, 145, .6 ); } }

	.scroll-up {
		display: block;
		position: absolute;
		bottom: -8px;
		border: none;
		left: 50%;
		transform: translateX( -50% );
		background-color: #121212;
		width: 60px;
		height: 60px;
		padding: 0;
		background-image: url( "../img/scroll-down-arrow.svg" );
		background-repeat: no-repeat;
		background-position: center center;

		transform: rotate( 180deg ) translateX( 50% ); } }

.alert {
	position: fixed;
	top: 65px;
	margin-top: 0;
	z-index: 8;
	transition: top .3s ease;
	text-decoration: none;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 700;
	padding: 15px 0; }


@media (max-width: 800px) {
	.alert {
		top: 53px;
		transition: top .3s ease; }

	.newsletter {

		.page-container {
			padding-top: 20px; }

		h2 {
			margin-bottom: 30px; }

		hr {
			margin-bottom: 30px; }

		p {
			font-size: 18px; }

		input[type="text"], input[type="email"], button[type="submit"] {
			font-size: 16px;
			margin-bottom: 30px; }

		button[type="submit"] {
			margin-top: -100px;
			margin-bottom: 100px; } } }
