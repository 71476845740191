.slider {
	width: 100%;
	overflow: hidden;
	height: 700px;
	position: relative;
	transition: height .3s ease; }

.slide {
	height: 100%;
	overflow: hidden;
	position: absolute;
	top: 0;
	width: 100%;
	background-size: cover;
	background-position: center center;

	background-repeat: no-repeat;
	transition: all .4s ease;
	text-shadow: 0 0 10px rgba( 0, 0, 0, .3 );

	&::after {
		content: "";
		display: none;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba( 0, 0, 0, .2 ); }

	&.active {
		right: 0 !important;
		transition: all .4s ease; }

	&.previousSlide {
		right: 100% !important;
		transition: all .4s ease; }

	&.slide1 {
		background-image: url( "../img/slider/slider1.jpg" ); }

	&.slide2 {
		background-image: url( "../img/slider/slider2.jpg" );
		z-index: 10; }

	&.slide3 {
		background-image: url( "../img/slider/slider3.jpg" );
		z-index: 15; }

	.slide-content {
		position: relative;
		z-index: 1;
		text-align: center;
		color: #fff;
		margin-top: 170px;
		transition: margin-top .3s ease;

		h2 {
			font-size: 0;
			height: 30px;
			margin: 0 auto;
			margin-bottom: 25px;
			background: url( "../img/logo_white.svg" ) no-repeat center center; }


		h3 {
			font-size: 63px;
			text-transform: uppercase;
			line-height: 20px;
			margin-top: 55px;
			transition: font-size .3s ease; }

		.second-line {
			display: block;
			margin-top: 25px;
			font-size: 42px;
			text-transform: uppercase;
			line-height: 70px;
			transition: font-size .3s ease, margin-top .3s ease; } }


	img {
		position: fixed;

		top: 0;
		min-height: 100%;

		height: auto;
		top: 0;
		left: 0; } }


.controls {

	.prev-arrow, .next-arrow {
		width: 69px;
		height: 131px;
		position: absolute;
		z-index: 9999;
		top: 55%;
		opacity: .2;
		cursor: pointer;
		transform: translateY( -50% );
		border: none;

		&:hover {
			box-shadow: none; } }

	.prev-arrow {
		background: url( "../img/prev-arrow.svg" ) no-repeat center center;
		left: 50px; }

	.next-arrow {
		background: url( "../img/next-arrow.svg" ) no-repeat center center;
		right: 50px; }

	.navigator {
		text-align: center;
		position: absolute;
		z-index: 9999;
		bottom: 80px;
		left: 50%;
		transform: translateX( -50% ); }

	.navigator-item {
		z-index: 2;
		display: inline-block;
		border: 1px solid #fff;
		width: 25px;
		height: 2px;
		opacity: .5;
		margin:  2px;

		&.active {
			opacity: .9;
			background-color: #fff; } } }

.scroll-down {
	display: block;
	border: none;
	padding: 0;
	margin: 0 auto;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate( -50% );
	z-index: 9998;
	width: 50px;
	height: 50px;
	background-color: #fff;
	background-image: url( "../img/scroll-down-arrow.svg" );
	background-position: center center;
	background-repeat: no-repeat;
	cursor: pointer; }

@media (min-width: 992px) {

	.slide {
		background-size: cover; } }

@media (max-width: 1300px) {
	.slider {
		height: 650px;
		transition: height .3s ease; }

	.slide {
		.slide-content {
			h3 {
				font-size: 45px;
				transition: font-size .3s ease; }

			.second-line {
				font-size: 36px;
				margin-top: 10px;
				transition: font-size .3s ease, margin-top .3s ease; } } } }

@media (max-width: 1080px) {
	.slider {
		height: 600px;
		transition: height .3s ease; }

	.slide {
		.slide-content {
			margin-top: 150px;
			transition: margin-top .3s ease;

			h3 {
				font-size: 36px;
				transition: font-size .3s ease; }

			.second-line {
				font-size: 25px;
				margin-top: 0;
				transition: font-size .3s ease, margin-top .3s ease; } } }

	.controls {
		.prev-arrow {
			background: url( "../img/prev-arrow-small.svg" ) no-repeat center center;
			left: 20px; }

		.next-arrow {
			background: url( "../img/next-arrow-small.svg" ) no-repeat center center;
			right: 20px; } } }

@media (max-width: 880px) {
	.slide {
		.slide-content {
			h3 {
				font-size: 25px;
				transition: font-size .3s ease; }

			.second-line {
				font-size: 20px;
				transition: font-size .3s ease; } } } }

@media (max-width: 640px) {
	.controls {
		.prev-arrow, .next-arrow {
			opacity: 0; } } }

@media (max-width: 500px) {
	.slider {
		height: 480px; }

	.slide {

		.slide-content {
			margin-top: 80px;

			h3 {
				font-size: 20px;
				transition: font-size .3s ease; }

			.second-line {
				font-size: 15px; } } } }
