@import "front/imports/link-hover-center";

.projects {
	padding-top: 50px;
	text-align: center;
	padding-bottom: 50px; }

.project-tile {
	overflow: hidden;
	width: 100%;
	box-shadow: 0 0 3px rgba( 0, 0, 0, .2 );
	border-radius: 5px;
	height: 450px;
	margin-bottom: 30px;
	display: inline-block;
	vertical-align: top;
	text-align: left;
	background-color: #fff;


	&.closed {
		text-align: left;

		img {
			-webkit-filter: grayscale(100%) {
    		filter: grayscale(100%); } } }
	.state-close,
	.state-complete {
		text-align: center;
		color: #fff;
		display: block;
		position: absolute;
		top: 60%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 700;
		text-shadow: 0 0 3px rgba( 0, 0, 0, .6 );

		&::before {
			content: "\f00c";
			font-family: "Font Awesome 5 Pro";
			font-size: 32px;
			color: #fff;
			position: absolute;
			top: -1rem;
			left: 50%;
			z-index: 51;
			text-shadow: 0 0 3px rgba( 0, 0, 0, .6 );
			transform: translateX(-50%) translateY(-50%); } }

	.state-complete {
		&::before {
			content: "\f164"; } }

	.info {
		padding: 0 15px 15px 15px; }

	.img-container {
		width: 100%;
		height: 150px;
		overflow: hidden;
		position: relative;

		.type, .location {
			position: absolute;
			z-index: 51;
			text-transform: uppercase;
			font-size: 12px;
			font-weight: 700;
			text-shadow: 0 0 3px rgba( 0, 0, 0, .6 ); }

		.type {
			top: 8px;
			left: 12px;
			color: #fff; }

		.location {
			bottom: 8px;
			right: 12px;
			color: #fff;
			width: auto;

			.fa {
				margin-left: 5px; } }

		a {
			display: block;
			min-height: 100%;
			min-width: 100%; } }

	img {
		display: block;
		min-width: 100%;
		max-width: 120%;
		height: auto;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%); }

	h2 {
		margin-top: 0;
		margin-bottom: 15px;
		font-size: 14px;
		text-transform: uppercase;
		text-align: center;
		padding: 15px 5px 0;
		max-height: 30px;
		overflow: hidden;

		a {
			color: $darkGray;
			position: relative;
			text-decoration: none;
			@include hover-effect(); } }

	a.seemore, a.your-project-here {
		display: block;
		padding: 10px;
		margin: 0 auto;
		text-align: center;
		text-decoration: none;
		color: #fff;
		background-color: $green;
		border-radius: 5px;
		font-weight: 500;
		position: relative;
		border: 2px solid $green;
		transition: all .3s ease;

		&:hover {
			background-color: #fff;
			color: $darkGray;
			transition: all .3s ease; } }


	&.disabled, &.closed {
		color: #999;

		.info .progress-fill {
			background-color: #999; } }

	&.partner {
		background-color: #f1f1f1; }

	&.coming-soon {
		form {
			input[type="email"] {
				border-radius: 5px;
				border: 1px solid #ddd;
				width: 100%;
				padding: 7px 12px;
				transition: border-color .3s ease;
				margin-bottom: 0;

				&:focus {
					outline: none;
					border-color: $green;
					transition: border-color .3s ease; } }

			button[type="submit"] {
				width: 100%;
				text-align: center;
				background-color: $green;
				border-radius: 5px;
				font-size: 14px;
				margin-bottom: 19px;
				margin-top: 10px;
				border: none;

				&:hover {
					color: #fff; } } }

		.disabled-btn {
			display: block;
			padding: 10px 0;
			margin: 0 auto;
			text-align: center;
			text-decoration: none;
			color: #000;
			background-color: transparent;
			border-radius: 5px;
			font-weight: 500;
			position: relative;
			border: 2px solid $green;
			position: absolute;
			bottom: 43px;
			left: 30px;
			right: 30px; } }


	&.landing {
		background: #f1f1f1;
		p {
			font-weight: 600;
			padding-top: 25px;
			height: 176px; } } }

.disabled-project-template {
	display: none; }

.alert {
	position: fixed;
	top: 65px;
	margin-top: 0;
	z-index: 8;
	transition: top .3s ease;
	text-decoration: none;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 700;
	padding: 15px 0; }
