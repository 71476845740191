.slider {
    position: relative;
    overflow: hidden;
    height: 437px; }

.slide.slide-project {
    //z-index: 99
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    transition: all .4s ease;
    text-shadow: 0 0 10px rgba( 0, 0, 0, .3 );


    &.active {
        right: 0 !important;
        transition: all .4s ease; }

    &.previousSlide {
        right: 100% !important;
        transition: all .4s ease; } }


.controls {
    .prev-arrow, .next-arrow {
        width: 69px;
        height: 131px;
        position: absolute;
        z-index: 9999;
        top: 55%;
        opacity: .2;
        cursor: pointer;
        transform: translateY( -50% );
        border: none;

        &:hover {
            box-shadow: none; } }

    .prev-arrow {
        background: url( "../img/prev-arrow.svg" ) no-repeat center center;
        left: 20px; }

    .next-arrow {
        background: url( "../img/next-arrow.svg" ) no-repeat center center;
        right: 20px; }

    .navigator {
        text-align: center;
        position: absolute;
        z-index: 9999;
        bottom: 20px;
        left: 50%;
        transform: translateX( -50% ); }

    .navigator-item {
        z-index: 2;
        display: inline-block;
        border: 1px solid #fff;
        width: 25px;
        height: 2px;
        opacity: .5;
        margin:  2px;

        &.active {
            opacity: .9;
            background-color: #fff; } } }



@media (max-width: 1300px) {
    .slider {
        height: 650px;
        transition: height .3s ease; }

    .slide {
        .slide-content {
            h3 {
                font-size: 45px;
                transition: font-size .3s ease; }

            .second-line {
                font-size: 36px;
                margin-top: 10px;
                transition: font-size .3s ease, margin-top .3s ease; } } } }

@media (max-width: 1080px) {
    .slider {
        height: 600px;
        transition: height .3s ease; }

    .slide {
        .slide-content {
            margin-top: 150px;
            transition: margin-top .3s ease;

            h3 {
                font-size: 36px;
                transition: font-size .3s ease; }

            .second-line {
                font-size: 25px;
                margin-top: 0;
                transition: font-size .3s ease, margin-top .3s ease; } } }

    .controls {
        .prev-arrow {
            background: url( "../img/prev-arrow-small.svg" ) no-repeat center center;
            left: 20px; }

        .next-arrow {
            background: url( "../img/next-arrow-small.svg" ) no-repeat center center;
            right: 20px; } } }

@media (max-width: 880px) {
    .slide {
        .slide-content {
            h3 {
                font-size: 25px;
                transition: font-size .3s ease; }

            .second-line {
                font-size: 20px;
                transition: font-size .3s ease; } } } }

@media (max-width: 640px) {
    .controls {
        .prev-arrow, .next-arrow {
            width: 16px;
            height: 16px; }

        .prev-arrow {
            left: 10px; }

        .next-arrow {
            right: 10px; } } }

@media (max-width: 500px) {
    .slider {
        height: 480px; }

    .slide {

        .slide-content {
            margin-top: 80px;

            h3 {
                font-size: 20px;
                transition: font-size .3s ease; }

            .second-line {
                font-size: 15px; } } } }
