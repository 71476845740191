.tell-us-more {
	text-align: center;
	margin: 40px 0; }

.asterisk {
	text-align: right;
 }	//margin-bottom: 30px

fieldset {
	border: none;
	padding: 0;
	// margin-bottom: 20px

	&.faq-form {
		padding-right: 0 !important; }

	legend {
		text-transform: uppercase;
		font-size: 18px;
		margin-bottom: 15px;
		color: $green; }

	label {
		font-weight: 700;
		font-size: 12px;
		text-transform: uppercase; }

	input, textarea {
		display: inline-block;
		width: 100%;
		margin-bottom: 20px;
		border-radius: 3px;
		border: 1px solid #ddd;
		padding: 5px 10px;
		transition: border-color .3s ease;

		&:focus {
			border-color: $green;
			outline: 0 !important;
			transition: border-color .3s ease; } }

	textarea {
		resize: vertical;
		min-height: 116px; }

	select:-moz-focusring {
		color: transparent;
		text-shadow: 0 0 0 #000; }

	select {
		border: none; }


	.select-style {
		margin-bottom: 20px;
		border: 1px solid #ddd;
		width: 100%;
		border-radius: 3px;
		overflow: hidden;
		background-color: #fff;
		background: #fff url( "../img/select-arrow.svg" ) calc(100% - 5px) 50% no-repeat; }


	.select-style select {
		cursor: pointer;
		padding: 6px 10px;
		width: 108%;
		border: none;
		box-shadow: none;
		background-color: transparent;
		background-image: none;
		appearance: none;
		margin-bottom: 0;
		height: 34px; }

	option {
		width: 100%; }

	.select-style select:focus {
		outline: none; } }

.has-error {
	.help-block {
		color: #a94442;
		float: right;
		font-size: 13px;
		margin-top: 3px; }
	input,select,label::before {
		border-color:#a94442 !important {} }
	&.checkbox {
    	.control-label::before {
      		border-color: #a94442; } } }

.has-error.check {
	.help-block {
		float: none;
		display: block; } }

.leftside-input {
	padding-left: 0; }

.rightside-input {
	padding-right: 0; }

.alert {
	position: fixed;
	top: 65px;
	margin-top: 0;
	z-index: 8;
	transition: top .3s ease;
	text-decoration: none;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 700;
	padding: 15px 0; }

[type="submit"], .btn {
	display: block;
	padding: 10px 15px;
	margin: 20px auto 60px;
	text-align: center;
	text-decoration: none;
	color: #fff;
	text-decoration: none;
	background-color: $green;
	border-radius: 5px;
	font-weight: 500;
	position: relative;
	border: 2px solid $green;
	transition: all .3s ease;
	white-space: normal;

	&:hover {
		background-color: #fff;
		color: $darkGray;
		transition: all .3s ease; }

	&:disabled {
		background: #a9a9a9;
		cursor: not-allowed;
		border: none;
		&:hover {
			color: #fff; } } }

.g-recaptcha {
	margin-left: -15px;
	margin-bottom: 2rem; }

.nobe {
	label {
		color: #dc3545; } }

input[type="checkbox"] {
	display: none;

	+ label {
		display: inline-block;
		cursor: pointer;
		position: relative;
		margin-left: 25px;
		margin-bottom: 10px; }


	&:checked + label::before {
		background-color: $green;
		background-image: url( "../img/checked.svg" );
		background-repeat: no-repeat;
		background-position: center center;
		transition: background-color .3s ease; }

	&+ label::before {
		vertical-align: middle;
		content: "";
		display: inline-block;
		margin-right: 5px;
		width: 16px;
		height: 16px;
		border: 2px solid $green;
		border-radius: 3px;
		position: absolute;
		left: -25px;
		top: 1px;
		transition: background-color .3s ease; } }


@media( max-width: 1080px ) {
	.leftside-input {
		padding-right: 0; }

	.rightside-input {
		padding-left: 0; }

	fieldset {
		&:first-child {
			padding-left: 0;
			padding-right: 0; }

		&:nth-child(2) {
			padding-right: 0;
			padding-left: 0; } } }

@media (max-width: 880px) {
	.alert {
		top: 53px;
		transition: top .3s ease; } }
