// dans l'ideal il faudrait virer le parent fieldset (pour input, textarea etc dans project-form.sass) ducoup je vais faire pareil mais avec une classe parent user
input[type="radio"] {
  display: none;
  +label {
    display: inline-block; 
    cursor: pointer;
    position: relative;
    margin-left: 25px;
    margin-bottom: 10px;
  }
  &:checked+label::before {
    background-color: $green; //background-image: url("fonts/checked.svg");
    background-repeat: no-repeat;
    background-position: center center;
    transition: background-color 0.3s ease;
    border: 2px solid $green;
  }
  &+label::before {
    vertical-align: middle;
    content: "";
    display: inline-block;
    margin-right: 5px;
    width: 14px;
    height: 14px;
    border: 2px solid #ddd;
    border-radius: 50%;
    position: absolute;
    left: -22px;
    top: 4px;
    transition: background-color 0.3s ease;
  }
}

.green {
  color: $green;
}

.btn-reverse {
  display: inline-block;
  background: transparent;
  color: $green;
  &:hover {
    background: $green;
    color: #fff;
  }
}

.user-nav {
  padding: 1rem 0;
  hr {
    width: 100%;
    display: block;
    height: 1px;
    border-bottom: 1px solid #ddd;
    margin-top: 1rem;
  }
  .container {
    position: relative;
  }
  .nav {
    margin: 0;
    list-style: none;
    padding: 0;
    margin-bottom: 40px;
    li {
      
      &.active {
        a {
          color: $green;
          border-bottom: 2px solid $green;
        }
      }
      a {
        text-align: center;
        display: inline-block;
        padding: .2rem;
        padding-bottom: 0;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
        position: relative;
        color: #000;
        font-size: 14px;
        transition: all 500ms ease-in;
        border-bottom: 2px solid transparent;
        &:first-of-type {
          padding-left: 0;
        }
        &:hover,
        &:focus {
        
         border-bottom: 2px solid $green;
          transition: all 300ms ease-in;
        }
      }
      i{
        color: $red;
        &.complete{
          color: $green;
        }
      }
      &:last-of-type {
        margin-right: 0;
      }
    }

  }
  .profil {
    padding: .4rem 0;
    color: #444444;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 12px;
    span.green{
      color: $green;
    }
    span.red{
      color: $red;
    }
  }
  .profile-completion {
    color: $green;
  }
  .progress-container {
    margin-top: 0.3rem;
  }
  .progress-fill{
    &.green{
      background-color: $green;
    }
    &.red{
      background-color: $red;
    }
  }
}

.page-user {
  margin-top: 50px;
  margin-bottom: 50px;
  h2 {
    text-transform: uppercase;
  }
  a{
    color: $green;
  }
  #lang{
    text-transform: uppercase;
  }
  label {
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
  }
  input {
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 3px;
    border: 1px solid #ddd;
    padding: 5px 10px;
    transition: border-color 0.3s ease;
  }
  input[type="checkbox"] {
    display: none;
  }
  [type="submit"],
  .btn {
    display: inline-block;
    margin: 0;
    margin-top: 10px;
    font-size: 16px;
    padding: 10px 25px; 
    
  }
  button,
  .button {
    text-transform: initial;
  }
  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
  select {
    border: none;
  }
  .select-style {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    width: 100%;
    border-radius: 3px;
    overflow: hidden;
    background-color: #fff;
    background: #fff url( "../img/select-arrow.svg" ) calc(100% - 5px) 50% no-repeat;
    select{
      height:34px;
    }
  }
}

.combodate{
  display: flex;
 
}

.combodate .select-style {
  width: 75px;
}


.select-style select {
  cursor: pointer;
  padding: 6px 10px;
  width: 108% !important;
  border: none;
  box-shadow: none;
  background-color: transparent;
  background-image: none;
  appearance: none;
  margin-bottom: 0;
  height:34px;
}
option {
  width: 100%;
}
.select-style select:focus {
  outline: none;
}

.page-login {
  h2 {
    margin-top: 0;
  }
  .subtitle {
    display: block;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 12px;
  }
}

.page-profile {
  margin-bottom: 50px;
  .panel,
  h2 {
    margin-bottom: 25px;
  }
  .inputfile {
    width: 0.1px; 
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .btn-file {
    font-size: 16px;
    display: block;
    width: 50%;
    margin: 0 auto;
    margin-top: 25px;
  }
  .btn-file {
    cursor: pointer;
    /* "hand" cursor */
  }
  .inputfile:focus+label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }
  input[type="password"] {
    position: relative;
    background: #fff url('../img/fa-lock.png') no-repeat;
    background-position: right;
  }
}

.page-invest {
  .invest-panel-text {
    font-size: 2.25rem;
    font-weight: 700;
    i{
      color:#5da691;
      font-size:3rem;
    }
  }

  .invest {
    font-family: "proxima-nova", "sans-serif";
    .col-md-9{
      .row{
        .col-md-6:last-child{
          padding-top: 1rem;
        }
      }
    }
    p{
      margin: .3rem 0;
    }
    .disabled{
      background: #ddd;
      
      > div{
        opacity: .6; 
        background: white;
      }
    }
    .aborted{
      z-index: 99;
      display: block;
      background: #333;
      color: white;
      padding:1rem 2rem;
      text-align: right;
      @media (max-width:767px) {
        margin:0 -20px;
      }
    }
    .imgContainer{
      overflow: hidden;
      position: relative;
      padding-left:0;
      padding-right: 0;
      display: flex;
      justify-content: center;
      img{
        margin: 0 auto;
        max-height: 100%;
        height: 100%;
      }
    }
    .amount{
      font-family: "proxima-nova", "sans-serif";
        position: absolute;
        font-size: 2rem;
        bottom:0;
        width: 100%;
        text-align: center;
        background: rgba(255,255,255,.9);
        padding:1rem;
        margin:0; 
      }
    padding: 0;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-bottom: 25px;
    &-content {
      padding: .5rem;
      h3 {
        margin: 0;
        line-height: 20px;
        margin-bottom: 10px;
      }
      p {
        font-family: "proxima-nova", "sans-serif";
        margin: 0;
        color: #444444;
        &.last {
          margin-top: 15px;
        }
      }
    }
    .buttons {
      display: flex !important;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      //flex-wrap: wrap;
      border-top: 1px dashed #ddd;
      margin-top: .5rem;
      a,span {
        text-align: center;
        flex: 25%;
        min-width: 25%;
        height: 120px;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding:1.25rem 1rem;
        text-decoration: none;
        transition: all 200ms ease-in;
        border-left: 1px dashed #ddd;
        i{
          display: block;
          font-size: 1.5rem;
          margin-bottom: .2rem;
        }
        &.first {
          border-left: none;
        }
      }
      span{
        color: #bbb;
        cursor: pointer;
      }
      .cancelBtn,
      .abortBtn{
        &:hover{
          background: #ccc;
          color: #fff
        }
      }
      
      a:hover {
        background: $green;
        color: #fff;
        transition: all 100ms ease-in;
      }
    }
  }
}

.page-quizz {
  .form-group {
    padding: 0 80px;
  }
  .text-success {
    color: #759f7a;
  }
  .text-danger {
    color: #e3ae47;
  }
  p {
    font-family: "proxima-nova", "sans-serif";
  }
}

.alert {
  position: relative;
  top:0;
  p {
    font-family: "proxima-nova", "sans-serif";
  }
}


@media (max-width:767px) {
  .user-nav {
    .nav {
      li {
        position: relative;
        a{
          text-align: left; 
        }
        i{
          position: absolute;
          right: 0;
          top:5px;
        }
      }
    }
  }
  .page-invest {
    .invest {
      padding: 0 20px;
      > .row{
        margin:0 -20px;
        .imgContainer{
          padding:0;
          img{
            //@at-rootmax-height: 200px;
            width: 100%; 
          }
        }
        .buttons{
          flex-direction: row;
          flex-wrap: wrap;
          a,span{
            border-left:0;
            border-bottom: 1px dashed #ddd;
            flex:100%;
            padding:1rem;
          }
          
        }
      }
    }
  }
}

@media (min-width:450px) {
  .user-nav {
    .nav {
      text-align: center;
      li {
        margin-right: 4%;
        display: inline-block;
      }
    }
  }
}

@media (min-width:1080px) {
  .user-nav {
    .nav {
      margin-bottom: 0;
    }
    .line {
      /*display: block;
      width: 1px;
      height: 75%;
      border-right: 1px solid #ddd;
      position: absolute;
      left: 63%;*/
    }
  }
  .panel-body {
    padding: 3rem 6rem;
  }
  .page-login {
    .col-login {
      h2,
      .subtitle {
        text-align: right;
      }
    }
  }
  .page-profile {
    .panel,
    h2 {
      margin-bottom: 50px;
    }
  }
  .page-invest {
    .invest {

        > .row{
          display: flex;
        }
        img{
          position: absolute;
        } 
      &-content {
        padding: .6rem;
      }
      .row {
        position: relative;
        margin: 0;
        >div {
          //padding: 0;
          //display: inline-block;
        }
      }
      .col-md-2 {
        border-left: 1px dashed #ddd;
        position: absolute;
        height: 100%;
        right: 0;
        a {
          height: 50%;
          display: block;
          text-align: center;
          padding: 15% 0;
        }
      }
    }
  }
}

@media (min-width:1300px) {
  .page-invest {
    .invest {
      &-content {
        padding: 1.5rem;
      }
    }
  }
}