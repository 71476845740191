@import "front/imports/link-hover-center";

nav {
	position: fixed;
	top: 0;
	min-height: 65px;
	z-index: 99;
	width: 100%;
	background-color: #fff;
	box-shadow: 0 0 20px rgba( 0, 0, 0, .2 );
	transition: margin-left .4s ease;


	.menu-btn {
		display: none; }

	ul {
		display: inline;

		&.social,
		&.lang {
			padding-left: 0px;
			height: 65px;
			line-height: 65px;
			position: absolute;
			right: 0;
			top: 2px;
			margin: 0px;

			& li::after {
				content: ""; }

			& a {
				color: $darkGray; } } }

	li {
		display: inline;
		list-style-type: none;
		margin: 10px;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 15px;


		a {
			text-decoration: none;
			color: $midGray;
			position: relative;
			transition: all .2s;

			&:hover {
				color: $green;
				transition: color .2s ease; } }

		&.nav-item a {
			@include hover-effect(); }

		&::after {
			content: "•";
			font-size: 14px;
			color: $green;
			margin-left: 9px;
			position: absolute;
			top: 21px; }

		&.last:after {
			content: ""; }

		&.main-item a {
			color: $darkGray; } } }

@media (max-width: 1300px) {
	nav {
		h1.logo {
			width: 28px;
			height: 28px;
			background: url( "../img/logo-small.svg" ) no-repeat center center;
 }			// transition: width .3s ease

		ul {
			padding-left: 10px;

			&.social, &.lang {
 } }				//margin-top: 21px

		li {
			&::after {
				top: 22px; } } } }

@media (max-width: 1080px) {
	nav {
		li {
			font-size: 13px;

			&::after {
				top: 22px; } } } }

@media( min-width: 880px ) {
	nav {
		-webkit-transform: translateZ(0); } }


@media (max-width: 880px) {
	nav {
		min-height: 55px;

		h1.logo {
			position: absolute;
			left: 50%;
			transform: translateX( -50% );
			margin-top: 13px; }

		.menu-btn {
			display: inline-block;
			color: $green;
			border: none;
			text-transform: lowercase;
			padding: 5px;
			font-size: 16px;
			position: absolute;
			top: 9px;

			&:hover {
				box-shadow: none; }

			&::before {
				content: "";
				display: inline-block;
				vertical-align: middle;
				width: 16px;
				height: 16px;
				background-image: url( ../img/menu-arrow.svg ); } }

		ul.nav {
			background-color: #fff;
			width: 200px;
			position: fixed;
			left: -200px;
			padding-top: 30px;
			box-sizing: border-box;
			top: 0;
			bottom: 0;
			margin: 0;
			transition: left .4s ease;

			&.menu-expanded {
				box-shadow: 0 0 15px rgba( 0, 0, 0, .4 );
				left: 0;
				transition: left .4s ease;


				li.nav-item {
					a::after {
						left: 0;
						right: 100%; }

					a:hover::after {
						right: 0; } } }

			li.nav-item {
				display: block;
				padding: 5px;

				&::after {
					content: none; } } }

		ul.social {
			height: 50px;
			line-height: 50px;
			margin-right: 20px; } }

	body.menu-expanded {
		nav {
			margin-left: 200px;
			transition: margin-left .4s ease;
			z-index: 9999; }

		nav .menu-btn::before {
			transform: rotate( 180deg ); } } }
