.site-plan {
	text-align: center;
	padding-top: 50px;
	padding-bottom: 40px;
	background-color: #fff;
	width: 100%;

	img {
		margin: 0 20px;
		width: 291px;
		transition: width .3s ease; }

	h2 {
		font-weight: 300;
		font-size: 36px;
		text-transform: uppercase;
		margin-bottom: 13px; }

	hr {
		border-color: $green; }

	p {
		font-size: 20px; }

	.tree-container {
		margin-top: 30px;
		padding-bottom: 50px; }

	.investor {
		text-align: right;

		.step1 {
			margin-top: 50px; }

		.step2 {
			margin-top: 12px; }

		.step3 {
			margin-top: 15px; } }

	.project-holder {
		text-align: left;

		.step1 {
			margin-top: 40px; }

		.step2 {
			margin-top: 15px; }

		.step3 {
			margin-top: 23px; } }

	.investor, .project-holder {
		display: inline-block;
		vertical-align: top;
		text-transform: uppercase;

		h3 {
			margin-top: 92px;
			font-size: 12px;
			font-weight: 300;

			b {
				font-size: 20px; } }

		b {
			display: block;
			font-weight: 700; }

		.step {
			font-size: 12px; }

		a {
			display: block;
			margin-top: 20px;
			background-color: $green;
			border: none;

			&:hover {
				color: #fff;
				box-shadow: 0 0 15px rgba( 93, 166, 145, .6 ); }

			b {
				display: inline; } } } }

@media (max-width: 1080px) {
	.site-plan {
		img {
			width: 0;
			height: 0;
			margin: 0 10px;
			padding: 166px 107px;
			background: url( "../img/tree-small.svg" );
			transition: all .3s ease; }

		a {
			font-size: 14px;
			padding: 10px 20px; }

		.investor {
			margin-left: 30px; } } }

@media (max-width: 880px) {
	.site-plan {
		img {
			display: none; }

		.investor, .project-holder {
			display: block;
			text-align: center;

			.step1 {
				margin-top: 30px; }

			a {
				width: 70%;
				margin: 20px auto 0; } }


		.investor {
			margin-left: 0;

			h3 {
				margin-top: 40px; } } } }

@media (max-width: 500px) {
	.site-plan {
		h2 {
			margin-top: 0;
			margin-bottom: 35px; }
		hr {
			margin-bottom: 40px; }

		.investor, .project-holder {
			.step3 {
				margin-bottom: 25px; }

			a {
				padding: 20px;
				width: 70%;
				margin: 0 auto; } } } }
