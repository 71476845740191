.progress-container {
	display: block;
	position: relative;
	margin: 5px -1px;
	height: 9px;
	border-radius: 5px;
	box-shadow: 0 0 2px rgba( 0, 0, 0, .4 ) inset;
	box-sizing: border-box;
	// border: 1px solid #ddd
	overflow: hidden; }

.progress-fill {
	display: block;
	position: absolute;
	max-width: 100%;
	min-width: 9px;
	border-radius: 5px;
	top: 0;
	bottom: 0;
	left: 0;
	background-color: $lightGreen;
	&.max {
		background-color: $green; }
	&.closed {
		background-color: #999; }
 }	// background-image: url( "../img/progress-fill.png" )

.disabled {
	.progress-fill {
		background-image: none; } }
