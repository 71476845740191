.page-title {
	text-align: center;
	margin-bottom: 20px;
	padding-top: 40px; }

.category {
	h2 {
		margin-bottom: 5px;
		margin-top: 30px;

		&:first-of-type {
			margin-top: 0.83em; } }

	&:last-of-type {
		padding-bottom: 50px; } }

.question {
	font-size: 16px;
	display: inline-block;
	margin: 3px 0;
	font-weight: 400;
	text-decoration: none;
	color: $green;
	position: relative;
	transition: all .3s ease;

	&:hover {
		text-decoration: underline; } }

.q, .a {
	font-family: "proxima-nova";
	font-weight: 700;
	font-size: 18px; }

.question-expanded {
	font-weight: 700;
	margin-left: 20px !important;
	margin-top: 20px;
	transition: all .3s; }

.answer-container {
	height: 0;
	overflow: hidden;
	opacity: 0;
	padding: 0;
	margin: 0;
	width: 90%;
	transition: all .3s;

	&.show {
		opacity: 1;
		height: auto;
		transition: all .3s;
		margin-bottom: 25px;
		margin-left: 20px; } }

@media (max-width: 700px) {
	.question {
		margin: 10px 0; }

	.question-expanded {
		margin-top: 20px; }

	.answer-container.show {
		margin-bottom: 35px; } }
