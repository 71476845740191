/* VARIABLES */
$green: #5da691;
$lightGreen : #9dd2c3;
$lightGrey: #c9c9c9;
$lighterGrey: #fbfbfb;
$red: #d0594b;
$blue: #55b2d0;
$duration: .3s;
$darkGray: #3c3c3b;
$midGray: #9d9d9d;

$fontStack: 16px/1.5 "proxima-nova", "Proxima Nova", sans-serif;
