footer {
	background-color: #121212;
	padding: 40px 0 15px;
	margin-bottom: 0;
	// height: 310px
	width: 100%;
	position: relative;
	z-index: 9999;
	clear: both;

	.col {
		width: 23%;
		display: inline-block;
		color: #fff;
		position: relative;
		vertical-align: top;

		img {
			vertical-align: middle;
			margin-right: 5px;
			margin-top: -5px; }

		h3 {
			margin-top: 10px;
			margin-bottom: 7px;
			display: inline-block;
			font-size: 20px;
			transition: font-size .3s ease;
			text-transform: uppercase; }

		hr {
			margin-left: 0;
			margin-bottom: 7px;
			margin-top: 0;
			width: 2.3em;
			border-color: $green; }

		p {
			font-size: 14px; } }

	.col2 {
		width: 23%;
		display: inline-block;
		margin: 0 14.9%;

		div:first-of-type {
			margin-top: 14px; }

		a {
			display: inline-block;
			color: #fff;
			text-decoration: none;
			font-family: "Forum";
			font-size: 14px;
			margin-bottom: 3px;
			position: relative;
			transition: all .2s;

			&::after {
				content: "";
				display: block;
				position: absolute;
				bottom: -2px;
				left: 0;
				right: 100%;
				height: 1px;
				background-color: $green;
				transition: all .2s ease; }

			&:hover {
				color: $green;
				transition: all .2s; }

			&:hover::after {
				right: 0;
				transition: all .2s ease; } } }

	.col3 {
		blockquote {
			font-family: "Forum";
			font-size: 14px;
			margin-left: 0;

			a {
				color: $green;
				text-decoration: none;

				&:hover {
					text-decoration: underline; } } }

		cite {
			font-size: 14px;
			font-family: "Forum";
			font-style: normal;
			color: #808080;

			a {
				color: inherit;
				text-decoration: none;

				&:hover {
					text-decoration: underline; } } } }

	.cgu-link {
		text-align: center;
		color: #fff;
		font: $fontStack;
		font-size: 12px;

		a {
			color: $green;
			text-decoration: none;
			font: $fontStack;
			font-size: 12px;

			&:hover {
				text-decoration: underline; } } }

	.footer-print {
		text-align: center;
		font: $fontStack;
		font-size: 12px;
		color: rgb(78, 78, 78);
		padding-bottom: 15px;

		a, i {
			color: rgb(100, 100, 100); } } }



@media (max-width: 1300px) {
	footer {
		.col {
			h3 {
				font-size: 18px;
				transition: font-size .3s ease; } } } }

@media (max-width: 1080px) {
	footer {
		.col {
			img {
				margin-top: 0; }

			h3 {
				font-size: 15px;
				transition: font-size .3s ease; }

			hr {
				margin-top: 5px; } } } }
@media (max-width: 880px) {
	footer {
		height: auto;

		.col {
			width: 29%; }

		.col2 {
			margin: 0 5%; } } }

@media (max-width: 640px) {
	footer {
		.col {
			width: 100%;

			h3 {
				margin-top: 20px; } }

		.col2 {
			margin: 0; }

		.cgu-link {
			margin-top: 25px; } } }

@media (max-width: 500px) {
	footer {
		.col {
			box-sizing: border-box; } } }
