body.menu-expanded {
	&:before {
		content: "";
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba( 0, 0, 0, .4 );
		z-index: 9999; } }

.page-container {
	width: 1170px;
	/*min-height: 100%*/
	margin: 0 auto;
	position: relative;
	transition: width .3s ease; }

.page-wrap {
	margin-top: 65px;
	/*min-height: 100%*/
	/*margin-bottom: -290px*/
	transition: margin-top .3s ease;

	/*&::after
		content: ""
		display: block
		height: 290px
		background-color: #121212*/ }

@media (max-width: 1300px) {
	.page-wrap {
		margin-top: 70px; }

	.page-container {
		width: 980px;
		transition: width .3s ease; } }

@media (max-width: 1080px) {
	.page-container {
		width: 850px;
		transition: width .3s ease; } }

@media (max-width: 880px) {
	.page-wrap {
		margin-top: 53px;
		transition: margin-top .3s ease; }

	.page-container {
		width: 600px;
		transition: width .3s ease; } }

@media (max-width: 640px) {
	.page-container {
		width: 480px;
		transition: width .3s ease; } }

@media (max-width: 500px) {
	.page-container {
		width: 100%;
		padding: 0 20px;
		box-sizing: border-box;
		transition: width .3s ease; } }

