.risk-container {
	display: block;
	padding: 5px 0;
	.tooltip {
		font-size: 16px;
		top: 2px; } }

.risk-item {
	font-family: "proxima-nova";
	font-size: 12px;
	padding: 5px;
	display: inline-block;
	line-height: 8px;
	width: 20px;
	height: 20px;
	border: 1px solid $green;
	border-radius: 5px;
	text-align: center;
	box-sizing: border-box;
	color: $green;
	background-color: transparent;
	font-weight: 400; }

.risk-container > .risk-limit {
	color: #fff;
	background-color: $green;
	font-weight: 700; }
