@import "front/base/bootstrap";
@import "front/imports/variables";
@import "front/imports/commun";
@import "front/imports/panels";
@import "front/account";
@import "front/base/base";
@import "front/layouts/main";
@import "front/layouts/nav";
@import "front/modules/slider";
@import "front/modules/site-plan";
@import "front/modules/newsletter-cta";
@import "front/modules/footer";
@import "front/modules/project";
@import "front/modules/hero";
@import "front/modules/project-tiles";
@import "front/modules/desc-row";
@import "front/modules/desc-row-listing";
@import "front/modules/project-progress";
@import "front/modules/project-form";
@import "front/modules/project-slider";
@import "front/modules/invest";
@import "front/modules/share";
@import "front/modules/risk";
@import "front/user/main";
@import "front/modules/faq-question";

@import 'admin/vendor/flatpickr';

.cookie-consent-container{ 
  position: fixed;
  bottom: 0; 
  width: 100%;
  background-color: #000;
  color: white;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  a{ 
    color: #5da691;
  }
  .btn{
    display: inline-block;
    background-color: #5da691;
    border: none;
    padding: 7px 10px;
    border-radius: 6px;
    font-size: 14px;
    margin: 10px; 
    text-transform: none;
  }
}

/* ===============
   ALERT
   =============== */

.alert {
  display: block;
  position: initial;
  width: 100%;
  margin: 0 auto;
  padding:1rem;
  text-align: center;
  border-radius: 0;
  font-family: "proxima-nova", "sans-serif";
  &.small {
    text-transform: none;
    font-weight:400;
    display: block;
  }
  p{
    text-align: center !important;
  }
}

.alert-warning {
    background-color: #fcf8e3;
    border-color: #faf2cc;
    color: #8a6d3b;
    a{
      color: #8a6d3b;
      font-weight: 700;
    }
}

.alert-error {
      background-color: #f2dede;
    border-color: #ebcccc;
    color: #a94442;
}

.alert-success {
  background-color: #dff0d8;
    border-color: #d0e9c6;
    color: #3c763d;
}

input,
textarea,
select {
  display: block;
  margin-bottom: 1.5em;
}

input{
  height: 36px;
  &#firstname,
  &#lastname{
    text-transform: capitalize;
  }
}

/*.page-wrap::after { background: #121212; }*/

#dynamiqueArray.fiveColumns td,
#dynamiqueArray.fiveColumns th {
  width: 20%;
}

.slide a {
  text-shadow: none
}

.home-slider .slide::after {
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,.2);
}

article.project .page-container p {
  margin-bottom: 20px;
}

article.project .page-container ul li {
  font-family: Forum;
  font-size: 16px;
}

.project-tile .desc-row.render {
  font-size: 14px
}

.project-tile .desc-row.render:lang(nl) {
  font-size: 14px
}

.clearfix {
  clear: both;
}

.grillades-ban-desktop img,
.grillades-ban-mobile img {
  max-width: 100%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.hidden-mobile {
    margin:10px 3px;
}

@media (max-width: 600px) {
  .hidden-mobile {
    display: none;
  }
}

@media (max-width: 1079px) {
  .grillades-ban-desktop {
    display: none;
  }
}

@media (min-width: 1079px) {
  .grillades-ban-mobile {
    display: none;
  }
}

@media (min-width: 1079px) and (max-width: 1299px) {
  .grillades-ban-desktop img {
    max-width: 80%;
  }
}

#langSwitcher,
#account {
  display: inline-block;
  /*position: absolute; top: 2px; right:120px;*/
  text-transform: uppercase;
}

#mobileLangSwitcher {
  display: none;
}

#account {
  ul,
  dt,
  dd {
    width: auto;
  }
  dd {
    ul {
      right: 0;
      left: inherit;
      width: 140px;
      li {
        text-align: right;
        font-size: 13px;
        a {
          padding: 5px 15px;
        }
      }
    }
  }
}

.dropdown dd,
.dropdown dt,
.dropdown ul {
  margin: 0px;
  padding: 0px;
  width: 50px;
  text-align: center;
  font: 16px/1.5 proxima-nova, "Proxima Nova", sans-serif;
  font-weight: 700;
  font-size: 15px;
}

.dropdown dd {
  position: relative;
}

.dropdown a:hover {
  color: #5da691;
}

.dropdown dt a {
  text-decoration: none;
}

.dropdown dt a span {
  cursor: pointer;
  display: block;
  padding: 5px;
  color: #5da691;
}

.dropdown dd ul {
  background: #fff;
  display: none;
  left: 0px;
  padding: 5px 0px;
  position: absolute;
  top: 2px;
  list-style: none;
  transition: all .2s;
}

.dropdown span.value {
  display: none;
}

.dropdown dd ul li {
  margin: 0;
}

.dropdown dd ul li::after {
  display: none;
}

.dropdown dd ul li a {
  padding: 5px;
  display: block;
  transition: all .2s;
}

.dropdown dd ul li a:hover {}

@media (max-width: 880px) {
  #langSwitcher {
    display: none;
  }
  #mobileLangSwitcher {
    padding: 0;
    background: none;
    list-style: none;
    margin: 20px 0 0 0;
    display: block;
  }
  #mobileLangSwitcher li {
    padding: 0;
    margin-left: 0;
    text-transform: uppercase;
  }
  #mobileLangSwitcher li::after {
    content: "/";
    position: static;
  }
  #mobileLangSwitcher li:last-child::after {
    content: "";
    display: none;
  }
}

.home-slider {
  .controls button{
    display:none;
  }
  height:calc(100vh - 55px);
  @media (min-width: 880px) {
    height: 700px;
    .controls button{
      display:block;
    }
  }
  .controls .navigator {
    text-align: center;
    position: absolute;
    z-index: 9999;
    bottom: 80px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.contact-container {
  margin-top: 50px;
}

// about
.vcard,
footer .col {
  vertical-align: top
}

.hero h1 {
  margin-bottom: 5px;
  font-size: 35px;
  margin-top: 0;
  line-height: 1;
  -webkit-transition: font-size .3s ease;
  transition: font-size .3s ease
}

.hero h2 {
  font-size: 18px;
  max-width: 700px;
  margin: 0 auto;
  font-weight: 400;
  font-family: Forum
}

.hero h1,
.hero h2 {
  position: relative;
  z-index: 6
}

.about-container {
  margin-top: 50px
}

.ecco-logo {
  display: block;
  margin: 0 auto
}

.section-title {
  margin-top: 10px;
  margin-bottom: 15px
}

.section-title::after {
  display: block;
  width: 100px;
  margin: 10px auto 0;
  height: 3px;
  background-color: #5da691
}

.activity,
.goals,
.values {
  margin-bottom: 50px
}

.activity p,
.goals p,
.values p {
  font: 16px/1.5 proxima-nova, "Proxima Nova", sans-serif;
  font-size: 16px;
  width: 50%;
  margin: 0 auto;
  text-align: center
}

.goals p,
.values p {
  width: 70%
}

.project{
  .render{
    &-value{
      float: none;
      &-bonus{
        background: #FF5722;
          border-color: #FF5722;
      }
    }
    .tooltip{
      margin: 0px 0 0 3px;
      top: 2px;
    }
  }
}

.faces {
  margin-bottom: 50px
}

.faces .section-title {
  margin-bottom: 0
}

.fonctionnement iframe,
.fonctionnement img {
  max-width: 80%;
  display: block;
  margin: 20px auto 40px
}

.fonctionnement img {
  border-radius: 5px
}

.fonctionnement iframe {
  max-height: 360px!important
}

.about-container {
  >p {
    font-family: "proxima-nova", "sans-serif";
  }
  text-align: center;
  h2 {
    &::after {
      display: block;
      width: 100px;
      margin: 10px auto 0;
      height: 3px;
      background-color: #5da691;
      content: '';
    }
  }
  .col-md-4 {
    img {
      display: block;
      margin: 0 auto 25px;
      border-radius: 50%;
      width: 128px;
    }
    .fn {
      display: block;
      font-size: 20px
    }
    .role {
      display: block;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 14px
    }
    a {
      color: #5da691
    }
  }
  .col-md-6 {
    .content {
      p {
        font-family: "proxima-nova", "sans-serif";
      }
    }
  }
  .button {
    background-color: #5da691;
    border-radius: 6px;
    display: block;
    width: 300px;
    font-size: 16px;
    font-weight: 700;
    padding: 15px;
    margin: 30px auto 80px;
    border: 2px solid #5da691;
    &:hover {
      background: 0 0;
      box-shadow: none
    }
  }
}

.quizz_seemore {
  background-color: #5da691;
  border-radius: 6px;
  display: inline-block;
  width: auto;
  font-size: 16px;
  font-weight: 700;
  padding: 15px;
  margin: 30px auto 80px;
  border: 2px solid #5da691;
  color: white;
  text-decoration: none;
  &:hover {
    background: 0 0;
    box-shadow: none;
    color: #5da691;
  }
}

@media (min-width:1080px) {
  .about-container {
    >p {
      font-family: "proxima-nova", "sans-serif";
      width: 50%;
      margin: 0 auto;
    }
    .col-md-4,
    .col-md-6 {
      .content {
        padding: 45px 70px;
      }
    }
    .button {
      background-color: #5da691;
      border-radius: 6px;
      display: block;
      width: 300px;
      font-size: 16px;
      font-weight: 700;
      padding: 15px;
      margin: 30px auto 80px;
      border: 2px solid #5da691;
      &:hover {
        background: 0 0;
        box-shadow: none
      }
    }
  }
}

.invest{
  ul.steps{
    max-width: 100%;
    width: 100%;
    display: flex;
    list-style: none;
    justify-content: space-evenly;
    background: #f1f1f1;
    padding:1rem 0;
    margin: 0 0 2rem;
    text-align: center; 
    @media (max-width:767px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    li{
      flex: auto;
      a,span, button{
        padding:0; 
        background: none;
        border:none;
        text-decoration: none;
        text-transform: none;
        margin:0;
        font-size: 1rem;
        font-weight: 400;
        display: inline-block;
        &:hover{
          box-shadow: none;
          opacity: .9;
          text-decoration: none;
        }
        &.active{
          font-weight: 700;
          text-decoration: underline;
        }
        &.check,
        &.inactive{
          color: $green;
          opacity: .8;
        }
        &.check{
          &:before{        
            margin-right:.5rem;
            content: "\f058";
            color: $green;
            font: normal normal normal 14px/1 "Font Awesome 5 Pro";
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
          }
        }
      }
      span{
        &.inactive{
          cursor: not-allowed;
          color: #ccc;
        }
      }
    }
  }
  
  .group-number{
    display: flex;
    margin:1rem 0;
    span, input{
      height: 40px;
      
    }
    .desc{
      min-width: 250px;
      margin: 0 5px;
      line-height: 40px;
    }
    input{
      margin: 0 5px;
      width: 60px;
    }
    .btn{
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      padding:0 15px;
      margin:0;
      border:none;
    }
  }
  
  #dynamiqueArray{
    &.loading{
      position: relative;
      &::after{
        content:'';
        position: absolute;
        top:0;
        left: 0;
        z-index: 9;
        width:100%;
        height: 100%;
        display: block;
        background: transparent url('img/loader.gif') center center no-repeat;
        background-size: 50px;
      }
      table{
        transition: all .3s;
        opacity: .8;
      }
    }
  }

  .btn{
    display: table;
    margin:3rem auto 4rem;
    &.pay,
    &.paybank{
      margin: 20px auto 60px;
      color: $green;
      text-transform: uppercase;
      font-size: 18px;
      &:hover{
        color: white;
      }
      span{
        text-transform: lowercase;
      }
    }
    &.edit{
      margin:1rem 0; 
    }
  }

  &.checkout{
    .invest{
      padding-top:0;
    }
    .amount{
      h2,
      .btn{
        margin:0px 0;
        display: inline-block;
      }
      .btn{
        margin-left: 1rem;
      }
    }
    .imgContainer img{
      width: 100%;
    }
    .banks{
      margin:1rem 2rem;
      .bank{
        display: flex;
        flex-wrap: wrap;
        margin: .5rem 0;
        padding: 8px 15px;
        transition: all .3s;
        border-radius: 5px;
        &:hover{
          background: $green;
          color: white;
          text-decoration: none;
        }
        img{
          margin-right: 1rem;
        }
        span{
          font-weight: 600;
          font-size: 18px;
        }
      }
    }
    .invest-content{
      background: #f1f1f1;
      padding:10px 40px 30px;
    }
    .project-status{
      margin-bottom: 20px;
      
      .item{
        width: auto;
        background:none;
        padding: 0;
        margin-bottom: 15px;
      }
    }
    .amount{
      margin-top: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      h2{
        display: block;
      }
      a{
        height: 50px;
        flex: 0 1 auto;
        display: block;
      }
    }
    hr{
      border:1px #f1f1f1 solid;
      margin: 1rem 0 3rem;
      width: 100%;
    }
    padding-bottom: 3rem;
  }

  &.done{
    padding-bottom: 3rem;
    p{
      text-align: left;
    }
  }
}

