.invest {
	padding-top: 50px;

	h1 {
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 30px; }

	form {
		width: 100%;
		clear: both;

		&:after {
			content: "";
			display: block;
			clear: both; } }

	.project-name {
		display: block;
		text-transform: none;
		font-size: 25px; }

	.sep {
		width: calc(100% - 30px);
		float: left;
		height: 1px;
		background: rgba(0,0,0,0.1);
		margin: 15px 15px 25px; }

	.project-status {
		margin-bottom: 40px;
		.item {
			margin: 0 auto;
			padding: 10px 20px;
			width: 320px;
			display: flex;
			justify-content: space-between;
			background: rgba(0,0,0,0.03);
			&-txt {
				font-weight: 700;
				display: block; }
			&-value {
				font-size: 14px;
				padding: 0px 10px;
				display: inline-block;
				height: 24px;
				line-height: 24px;
				border-radius: 5px;
				box-sizing: border-box;
				color: #fff;
				background-color: #5da691;
				font-weight: 700;
				&-alter {
					background-color: #FF5722; } }
			&-warning {
				display: block;
				margin-top: 30px;
				text-align: center; } } }

	.sub-fee {
		font: $fontStack;
		font-weight: 700; }

	a {
		color: $green;
		text-decoration: none;

		&:hover {
			text-decoration: underline; } }

	table {
		width: 100%; }

	thead th {
		font-size: 12px;
		text-transform: uppercase;
		padding: 10px 0;
		line-height: 15px; }

	th {}


	td, th {
		width: 25%;
		padding: 0;
		text-align: center;
		padding: 1px 0;
		border-collapse: collapse;
		border: 1px solid #ddd; }


	.btn {
		color: #fff;
		text-transform: none;

		&:hover {
			text-decoration: none;
			color: $green; } } }
