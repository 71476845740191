/* COMMUN */
html, body {
  min-height: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  font: $fontStack;
}

hr {
  margin: 0;
}


.tooltiptext {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  width: 200px;
  font-size: 13px;
  text-align: center;
  line-height: 1.2em;
  padding: 8px 20px;
  border-radius: 6px;
  font-weight: 500;
  font-family: proxima-nova, "Proxima Nova", sans-serif; 

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 8px;
  margin-left:-100px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
}
/* Show the tooltip text when you mouse over the tooltip container */

.tooltip{
  position: relative;
  cursor: pointer;
  margin-left:10px;
  &:hover{ 
    .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
  }
}
