html {
	height: 100%;
	margin: 0;
	margin-bottom: -65px; }

body {
	height: 100%;
	margin: 0;
	margin-bottom: -65px;
	font-family: "proxima-nova", "sans-serif"; }

p {
	font-family: "Forum"; }

address {
	font-style: normal; }

.fa-twitter:before {
	content: "\f099"; }

.fa-facebook:before {
	content: "\f09a"; }

.fa-linkedin:before {
	content: "\f0e1"; }

.logo {
	display: inline-block;
	background: url( "../img/logo.svg" ) no-repeat center center;
	width: 185px;
	height: 25px;
	text-indent: -999999px;
	vertical-align: middle;
	transition: width .3s ease; }

hr {
	width: 5em;
	border-color: #fff;
	border-top: none;
	border-left: none;
	margin: 0 auto; }

button, .button {
	font: $fontStack;
	border: 1px solid #fff;
	padding: 10px 25px;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 18px;
	color: #fff;
	background-color: transparent;
	cursor: pointer;
	text-decoration: none;
	position: relative;
	transition: all .2s ease;
	text-align: center;

	&:focus {
		outline: none; }

	&:hover {
		background-color: #fff;
		color: $green;
		transition: all .2s ease;
		box-shadow: 0 0 15px rgba( 0, 0, 0, .3 ); } }

.browsehappy {
	text-align: center;
	font: $fontStack; }
