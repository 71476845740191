.hero {
	text-align: center;
	width: 100%;
	height: 250px;
	box-sizing: border-box;
	transition: height .3s ease;
	display: table;
	background-size: cover;
	background-position: center center;
	background-attachment: scroll;
	background-repeat: no-repeat;
	color: #fff;
	box-sizing: border-box;
	position: relative;

	&::after {
		content: "";
		display: block;
		position: absolute;
		z-index: 5;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba( 0, 0, 0, .3 ); }

	.hero-content {
		display: table-cell;
		vertical-align: middle; }

	h1 {
		margin-bottom: 5px;
		font-size: 35px;
		margin-top: 0;
		line-height: 1;
		transition: font-size .3s ease; }

	h2 {
		font-size: 18px;
		max-width: 700px;
		margin: 0 auto;
		font-weight: 400;
		font-family: "Forum"; }

	h1, h2 {
		position: relative;
		z-index: 6; } }

.invest-hero {
	background-image: url( ../img/hero/Header-investisseurs.jpg ); }

.pp-hero {
	background-image: url( ../img/hero/Header-porteurs-projet.jpg ); }

.faq-hero {
	background-image: url( ../img/hero/Header-FAQ.jpg ); }

.contact-hero {
	background-image: url( ../img/hero/Header-contact.jpg ); }

.about-hero {
	background-image: url( ../img/hero/Header-about.jpg ); }

.user-hero {
	background-image: url( ../img/hero/Header-user.jpg ); }

@media (max-width: 640px) {
	.hero {
		h2 {
			font-size: 18px; } } }

@media (max-width: 500px) {
	.hero {
		h1 {
			font-size: 25px;
			transition: font-size .3s ease; }

		h2 {
			font-size: 16px;
			transition: font-size .3s ease; } } }
